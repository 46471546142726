import { render, staticRenderFns } from "./RoomControlH5.vue?vue&type=template&id=6592e936&scoped=true&"
import script from "./RoomControlH5.vue?vue&type=script&setup=true&lang=ts&"
export * from "./RoomControlH5.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./RoomControlH5.vue?vue&type=style&index=0&id=6592e936&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6592e936",
  null
  
)

export default component.exports