<template>
  <div class="home-container">
    <div class="header">
      <!-- <user-info
        class="header-item user-info"
        :user-id="userId"
        :user-name="userName"
        :user-avatar="userAvatar"
        @log-out="handleLogOut"
      ></user-info> -->
      <!-- <language-icon class="header-item language"></language-icon> -->
      <switch-theme v-show="false" class="header-item theme"></switch-theme>
    </div>
    <!-- <stream-preview v-if="!isMobile" ref="streamPreviewRef" ></stream-preview> -->
    <img src="" alt="" />
    <room-control
      ref="roomControlRef"
      :given-room-id="givenRoomId"
      :user-name="userName || userId"
      :user-info="userInfo"
      :has-meeting-id="hasMeetingId"
      :has-speak-id="hasSpeakId"
      :token="token"
      @create-room="handleCreateRoom"
      @enter-room="handleEnterRoom"
    ></room-control>
  </div>
</template>

<script>
import { Message } from 'element-ui';

import qs from 'qs';
// import UserInfo from "@/TUIRoom/components/RoomHeader/UserInfo";
// import LanguageIcon from "@/TUIRoom/components/base/Language.vue";
import SwitchTheme from '@/TUIRoom/components/base/SwitchTheme.vue';
// import StreamPreview from '@/TUIRoom/components/RoomHome/StreamPreview.vue';
import RoomControl from '@/TUIRoom/components/RoomWelcome/RoomControl';
import { getBasicInfo } from '@/config/basic-info-config';
// import TUIRoomEngine from '@tencentcloud/tuiroom-engine-js';
import useGetRoomEngine from '@/TUIRoom/hooks/useRoomEngine';
import isMobile from '../TUIRoom/utils/useMediaValue';

const roomEngine = useGetRoomEngine();
export default {
  name: 'Home',
  components: {
    // UserInfo,
    // LanguageIcon,
    // StreamPreview,
    RoomControl,
    SwitchTheme,
  },
  data() {
    return {
      meetingId: '',
      speakId: '',
      hasMeetingId: false,
      hasSpeakId: false,
      givenRoomId: '',
      basicInfo: null,
      userName: '',
      userAvatar: '',
      userId: '',
      isMobile,
      userInfo: {
        meetingTitle: '----',
        startTime: '----',
        meetingDuration: '--',
        compere: '--',
        realname: '--',
        telephone: '----',
        telephoneMask: '----',
      },
    };
  },
  async mounted() {
    // 获取meetingId
    sessionStorage.removeItem('tuiRoom-roomInfo');
    sessionStorage.removeItem('tuiRoom-userInfo');
    if (this.$route.query.speakId) {
      this.speakId = this.$route.query.speakId;

      if (this.$route.query.meetingId) {
        this.meetingId = this.$route.query.meetingId;
        this.hasMeetingId = true;
        this.hasSpeakId = true;
        this.getSpeakInfo();
      }
    } else {
      if (this.isMobile) {
        if (this.$route.query.meetingId) {
          this.meetingId = this.$route.query.meetingId;
        } else {
          // Message({
          //   message: '缺少关键参数',
          //   type: 'warning',
          // });
        }
        if (this.$route.query.token) {
          this.token = this.$route.query.token;
          this.getUserInfo();
        } else {
          // Message({
          //   message: '缺少关键参数',
          //   type: 'warning',
          // });
        }
        if (this.meetingId && this.token) {
          this.hasMeetingId = true;
          this.getInnerInfo();
        } else {
          this.hasMeetingId = false;
        }
      } else {
        if (this.$route.query.meetingId) {
          this.meetingId = this.$route.query.meetingId;
          this.hasMeetingId = true;
          this.getCompereInfo();
        } else {
          // Message({
          //   message: '缺少关键参数',
          //   type: 'warning',
          // });
          this.hasMeetingId = false;
        }
      }
    }

    // this.givenRoomId = this.$route.query.roomId || '';

    // if (sessionStorage.getItem('tuiRoom-userInfo')) {
    //   this.basicInfo = JSON.parse(sessionStorage.getItem('tuiRoom-userInfo'));
    // } else {
    this.basicInfo = await getBasicInfo();
    //   this.basicInfo && sessionStorage.setItem('tuiRoom-userInfo', JSON.stringify(this.basicInfo));
    // }
    // this.userName = this.basicInfo.userName;
    // this.userAvatar = this.basicInfo.userAvatar;
    // this.userId = this.basicInfo.userId;
    // const { sdkAppId } = this.basicInfo;
    // // 登录 TUIRoomEngine
    // await TUIRoomEngine.login({ sdkAppId, userId, userSig });
    // if (!this.isMobile) {
    //   this.$refs.streamPreviewRef.startStreamPreview();
    // }
  },
  methods: {
    getCompereInfo() {
      const qsData = qs.stringify({ meetingId: this.meetingId });
      this.$http.post('/api/meeting/compere/info', qsData).then((res) => {
        // console.log(res);
        if (res.data.code === 1) {
          const { telephone } = res.data.data;
          this.userInfo = res.data.data;
          this.userInfo.telephoneMask = telephone.replace(telephone.substring(3, 7), '****');
          const tuiRoomInfo = {
            sdkAppId: this.basicInfo.sdkAppId,
            userId: res.data.data.userId,
            userSig: res.data.data.usrSig,
            userName: res.data.data.compere,
            avatarUrl: res.data.data.avatarUrl,
            roomId: res.data.data.roomId,
            meetingId: res.data.data.meetingId,
            meetingStatus: res.data.data.meetingStatus,
          };
          sessionStorage.setItem('tuiRoom-userInfo', JSON.stringify(tuiRoomInfo));
        } else {
          Message({
            message: res.data.msg,
            type: 'warning',
          });
        }
      });
    },
    getSpeakInfo() {
      const qsData = qs.stringify({ meetingId: this.meetingId, speakId: this.speakId });
      this.$http.post('/api/meeting/speak/info', qsData).then((res) => {
        // console.log(res);
        if (res.data.code === 1) {
          const { telephone } = res.data.data;
          this.userInfo = res.data.data;
          this.userInfo.telephoneMask = telephone.replace(telephone.substring(3, 7), '****');
          const tuiRoomInfo = {
            sdkAppId: this.basicInfo.sdkAppId,
            userId: res.data.data.userId,
            userSig: res.data.data.usrSig,
            userName: res.data.data.realname,
            avatarUrl: res.data.data.avatarUrl,
            roomId: res.data.data.roomId,
            meetingId: res.data.data.meetingId,
            meetingStatus: res.data.data.meetingStatus,
            speakId: this.speakId,
          };
          this.givenRoomId = tuiRoomInfo.roomId;
          sessionStorage.setItem('tuiRoom-userInfo', JSON.stringify(tuiRoomInfo));
          sessionStorage.setItem('userInfo', JSON.stringify(res.data.data));
        } else {
          Message({
            message: res.data.msg,
            type: 'warning',
          });
        }
      });
    },
    getUserInfo() {
      const options = {
        method: 'get',
        headers: { Authorization: this.token },
        url: '/mine/info',
      };
      this.$http(options).then((res) => {
        // console.log(res);
        if (res.data.code === 1) {
          sessionStorage.setItem('userInfo', JSON.stringify(res.data.data));
        } else {
          Message({
            message: res.data.msg,
            type: 'warning',
          });
        }
      });
    },
    // 参会人员信息
    getInnerInfo() {
      const qsData = qs.stringify({ meetingId: this.meetingId, token: this.token });
      this.$http.post('/api/meeting/enter/room', qsData).then((res) => {
        // console.log(res);
        if (res.data.code === 1) {
          if (res.data.data.canEnter) {
            this.userInfo = res.data.data;
            const tuiRoomInfo = {
              sdkAppId: this.basicInfo.sdkAppId,
              userId: res.data.data.userId,
              userSig: res.data.data.usrSig,
              userName: res.data.data.userName,
              avatarUrl: res.data.data.avatarUrl,
              roomId: res.data.data.roomId,
              meetingId: res.data.data.meetingId,
            };
            this.givenRoomId = tuiRoomInfo.roomId;
            // console.log('roomeId', this.givenRoomId);
            this.userName = tuiRoomInfo.userName;
            sessionStorage.setItem('tuiRoom-userInfo', JSON.stringify(tuiRoomInfo));
          } else {
            Message({
              message: res.data.data.errMsg,
              type: 'warning',
            });
          }
        }
      });
    },
    setTUIRoomData(action, mode) {
      // const roomParam = this.isMobile
      //   ? this.$refs.roomControlRef.getRoomParam()
      //   : this.$refs.streamPreviewRef.getRoomParam();
      const roomParam = this.$refs.roomControlRef.getRoomParam();
      const roomData = {
        action,
        roomMode: mode || 'FreeToSpeak',
        roomParam,
      };
      sessionStorage.setItem('tuiRoom-roomInfo', JSON.stringify(roomData));
    },
    async checkRoomExistWhenCreateRoom(roomId) {
      let isRoomExist = false;
      const tim = roomEngine.instance?.getTIM();
      try {
        await tim.searchGroupByID(roomId);
        isRoomExist = true;
      } catch (error) {
        // 房间不存在
      }
      return isRoomExist;
    },
    // 创建房间时生成房间号
    async generateRoomId() {
      // const roomId = Math.ceil(Math.random() * 1000000);
      const { roomId } = this.userInfo;
      // console.log(roomId);
      const isRoomExist = await this.checkRoomExistWhenCreateRoom(String(roomId));
      if (isRoomExist) {
        return await this.generateRoomId();
      }
      return roomId;
    },
    // 处理点击【创建房间】
    async handleCreateRoom(mode) {
      this.setTUIRoomData('createRoom', mode);
      const roomId = await this.generateRoomId();
      this.$router.push({ path: 'room', query: { roomId } });
    },
    // 处理点击【进入房间】
    async handleEnterRoom(roomId) {
      this.setTUIRoomData('enterRoom');
      this.$router.replace({
        path: 'room',
        query: {
          roomId,
        },
      });
    },
    // 处理用户点击页面左上角【退出登录】
    handleLogOut() {
      // 接入方处理 logout 方法
    },
  },
};
</script>

<style>
@import '../TUIRoom/assets/style/black-theme.scss';
@import '../TUIRoom/assets/style/white-theme.scss';
</style>

<style lang="scss" scoped>
.home-container {
  width: 100%;
  height: 100%;
  background: var(--background-color-style);
  color: #b3b8c8;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: PingFangSC-Medium;
  transition: background 0.5s, color 0.5s;
  :not([class|='el']) {
    transition: background-color 0.5s, color 0.5s;
  }
  .header {
    width: 100%;
    position: absolute;
    top: 0;
    padding: 22px 24px;
    display: flex;
    align-items: center;
    .header-item {
      &:not(:first-child) {
        margin-left: 16px;
      }
      .language {
        cursor: pointer;
      }
      .theme {
        cursor: pointer;
      }
    }
  }
}
</style>
